// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_TTG8q{flex-direction:row;flex-grow:1;width:100%}.container_TTG8q,.menuRight_5iMJI{display:flex;height:100%}.menuRight_5iMJI{background-color:var(--color-supportive-3-200);width:14rem}.menu_FlcX4{display:flex;flex-direction:column;gap:1rem}.pullBottom_6WqY\\+{align-items:flex-end;display:flex;flex-grow:1}.menuItem_4RQQs{display:flex;flex-direction:row;justify-content:space-between;width:100%}.selected_RryN\\+{background-color:var(--color-supportive-3-400);border-radius:var(--border-radius-medium);font-weight:300!important;margin:-.5rem 0 -.5rem -.5rem;padding:.25rem .5rem}.search_VYpwJ,.selected_RryN\\+{width:calc(100% + 1rem)}.search_VYpwJ{margin:0 0 0 -.5rem}.content_QewwK{display:flex;flex-direction:column;flex-grow:1;height:100%;width:calc(100% - 14rem)}.item_QwoFy{margin-top:1rem;min-height:10rem;width:calc(33% - .5rem)}@media screen and (min-width:1441px){.item_QwoFy{width:calc(25% - .75rem)}}@media screen and (max-width:1024px){.item_QwoFy{width:calc(50% - .5rem)}}.row_gJ9HM{display:flex;flex:0 0 auto;flex-flow:row wrap;gap:1rem}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_TTG8q",
	"menuRight": "menuRight_5iMJI",
	"menu": "menu_FlcX4",
	"pullBottom": "pullBottom_6WqY+",
	"menuItem": "menuItem_4RQQs",
	"selected": "selected_RryN+",
	"search": "search_VYpwJ",
	"content": "content_QewwK",
	"item": "item_QwoFy",
	"row": "row_gJ9HM"
};
module.exports = ___CSS_LOADER_EXPORT___;
