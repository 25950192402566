
import PortfolioItem from "./components/Portfolio/components/PortfolioItem.vue";
import TableCellWarning from "./components/TableCellWarning/TableCellWarning.vue";
import Portfolios from "~/graphql/Portfolio/Portfolios.gql";

import {
  PropertyDecomissionedFilter,
  PropertySubTypeFilter,
  excludePropertyFromResultsFilter,
  excludeTenancyFromResultsFilter,
  TenancyDecomissionedFilter,
  TenancySoldFilter,
  getTenancyDefaultUrlQuery,
} from "~/helpers/apollo/apollo-portfolio-filter-helpers";
import { InputBuilder } from "~/helpers/apollo/apollo-portfolio-helpers.js";
import { getDefaultTenancyFilterBookmarkQuery } from "~/helpers/apollo/apollo-portfolio-query-helpers.js";

export default {
  components: { PortfolioItem, TableCellWarning },

  data() {
    return {
      search: "",
      searchVisible: false,
    };
  },

  apollo: {
    portfolios: {
      query: Portfolios,
      variables() {
        return new InputBuilder()
          .setFilter({ matches: [{ active: true }] }, "portfolio")
          .setFilter(new PropertyDecomissionedFilter(), "property")
          .setFilter(PropertySubTypeFilter(), "property")
          .setFilter(excludePropertyFromResultsFilter())
          .setFilter(new TenancyDecomissionedFilter(), "tenancy")
          .setFilter(new TenancySoldFilter(), "tenancy")
          .setFilter(excludeTenancyFromResultsFilter(), "tenancy")
          .setInput("periodType", "YEARLY")
          .setInput("rentCategory", "RENT")
          .build();
      },
      update: (data) => {
        return data.assetManagementPortfolios;
      },
    },

    defaultFilterBookmark: getDefaultTenancyFilterBookmarkQuery(),
  },

  computed: {
    portfoliosCount() {
      return this.portfolios?.metadata.totalCount;
    },

    propertiesCount() {
      return (
        this.portfolios?.items.flatMap((portfolio) => portfolio.assetManagementProperties?.metadata?.totalCount ?? 0).reduce((acc, count) => acc + count, 0) ??
        0
      );
    },

    tenanciesCount() {
      return (
        this.portfolios?.items
          ?.flatMap((portfolio) =>
            portfolio.assetManagementProperties?.items.flatMap((property) => property.assetManagementTenancies?.metadata?.totalCount ?? 0)
          )
          .reduce((acc, count) => acc + count, 0) ?? 0
      );
    },

    userLoading() {
      return this.$apollo.queries.user.loading;
    },

    portfoliosLoading() {
      return this.$apollo.queries.portfolios.loading;
    },

    portfoliosCountLoading() {
      return this.$apollo.queries.portfolios.loading;
    },

    propertiesCountLoading() {
      return this.$apollo.queries.portfolios.loading;
    },

    tenanciesCountLoading() {
      return this.$apollo.queries.portfolios.loading;
    },

    portfolioGroups() {
      if (!this.portfolios?.items) return [];

      return this.portfolios.items.reduce((acc, portfolio) => {
        if (!acc.includes(portfolio.type)) {
          acc.push(portfolio.type);
        }

        return acc;
      }, []);
    },
  },

  methods: {
    getPortfoliosByGroup(portfolioGroup) {
      return [...this.portfolios?.items].filter((portfolio) => portfolio.type === portfolioGroup).sort((a, b) => a.name.localeCompare(b.name));
    },

    unitsClickMore() {
      this.$amplitude.navigateToRentRoll();

      this.$router.push({
        name: `portfolio-tenancies`,
        query: getTenancyDefaultUrlQuery(this.defaultFilterBookmark?.id),
      });
    },

    propertiesClickMore() {
      this.$amplitude.navigateToProperties();

      this.$router.push({
        name: `portfolio-properties`,
      });
    },

    onSearchFocus() {
      this.searchVisible = true;
    },
  },
};
